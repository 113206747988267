import React from "react";

const Skills = ({ skill, color }) => {
  return (
    <div
      className={`${color} hover:bg-hover text-dark font-semibold py-0 px-2 rounded-xl cursor-pointer`}
    >
      {skill}
    </div>
  );
};

export default Skills;
