import React from "react";
import { FaGithubAlt } from "react-icons/fa";
import Skills from "../Skills";
import photo from "./blink1.png";
import photo2 from "./blink2.png";

const STACK = ["Go", "Charm", "Lipgloss", "Bubbles", "Bubble Tea", "Huh"];

const Blink = () => {
  return (
    <div className="w-full mt-10 md:mt-0 md:ml-10 md:w-3/4 lg:w-3/4 sm:mx-auto">
      <h2 className="text-pink text-start font-bold text-3xl mb-3">Blink</h2>
      <a
        href="https://github.com/echocaro/blink"
        className="flex items-center mb-2"
      >
        <FaGithubAlt className="text-purple" />
        <span className="text-white ml-2">Project Link</span>
      </a>
      <div className="flex flex-wrap gap-2">
        {STACK.map((tech) => (
          <Skills skill={tech} color={"bg-purple"} />
        ))}
      </div>
      <p className="text-white text-start mt-5 mb-5">
        Blink is a project that helps you take care of your eyes by reminding
        you to follow the 20-20-20 rule. Every 20 minutes, an alert will run and
        let you know it's time to look at something 20 feet away for 20 seconds.
        Once the 20 seconds are up, another alert will go off to let you know
        you can look at the screen again.
      </p>
      <div className="flex flex-col md:flex-row lg:flex-row">
        <p className="text-white text-start mr-2 mb-5">
          This project was built using Go, Charm's Bubbletea, Lipgloss, Huh, and
          Bubbles packages.
        </p>
        <img
          src={photo}
          className="w-full md:w-3/4 drop-shadow-xl flex justify-center"
        />
      </div>
      <p className="text-white text-start mt-5">
        This was my first time using Golang for a project. I'm a visual learner
        so I oped on using Charm's Bubble Tea, Lipgloss, and Bubbles package to
        help with the syling and logic of the app. As Charm's Bubble Tea follows
        the design paradigms of the Elm Architecture, I got to dive deeper into
        a project that mainly functions in 3 parts: model, view, and update.
      </p>
      <div className="flex flex-col mt-5 md:flex-row lg:flex-row">
        <img
          src={photo2}
          className="w-full md:w-2/4 flex justify-center drop-shadow-xl"
        />
        <p className="text-white mt-5 md:mt-0 md:mb-8 md:ml-2 lg:mb-8 lg:ml-2 text-start">
          One of the challenges I faced while working in this project was
          ensuring that the timer was working correctly. With a TUI project, you
          can't exactly log to stdout without potentially causing some weird
          side effects. In order to debug some small issues, I opted on adding
          logs that would get printed out to a file.
        </p>
      </div>
      <p className="text-white text-start mt-5">
        Another issue was creating tests. When it comes to writing tests over
        logic that deals with timers, you don't want to write tests that
        actually interact with the time that's set (in this case, 20 minutes and
        20 seconds). Utilizing dependency injection helped out a lot with this,
        as I was able to separate the actual times and inject a mock/stub a
        timer that is completely controllable from the tests.
      </p>

      <h2 className="text-white mt-8 text-start font-bold">Remaining work:</h2>
      <div className="flex flex-col mb-20">
        <div className="flex flex-row sm:flex-row items-center mb-2">
          <input
            id="default-checkbox"
            type="checkbox"
            value=""
            className="rounded"
            disabled
          />
          <label className="ml-2 flex flex-col sm:flex-row items-center font-medium text-white ">
            HomeBrew install support
          </label>
        </div>
        <div className="flex flex-row items-center">
          <input
            id="default-checkbox"
            type="checkbox"
            value=""
            className="rounded"
            disabled
          />
          <label className="ml-2 font-medium text-white">
            Windows compatability
          </label>
        </div>
      </div>
    </div>
  );
};

export default Blink;
