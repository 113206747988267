import React from "react";
import { Link } from "react-router-dom";

const Tabs = () => {
  return (
    <div className="flex md:flex-col lg:flex-col items-center justify-center space-x-2 md:space-x-0 lg:space-x-0 md:space-y-4 lg:space-y-4">
      <div className="text-white cursor-pointer hover:text-purple">
        <Link to="/">about</Link>
      </div>
      <div className="text-white cursor-pointer hover:text-purple">
        <Link to="/work">work</Link>
      </div>
      <div className="text-white cursor-pointer hover:text-purple">
        <Link to="/projects">projects</Link>
      </div>
    </div>
  );
};

export default Tabs;
