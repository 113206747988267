import React from "react";
import { Link } from "react-router-dom";
import pic1 from "./blink1.png";

const Projects = () => {
  return (
    <div className="w-full mt-5 md:mt-0 md:w-3/4 lg:mt-0 lg:w-3/4 sm:mx-auto md:ml-10 lg:ml-10">
      <div className="flex flex-col">
        <Link to="/blink">
          <div className="flex flex-col md:flex-row lg:flex-row">
            <div className="flex flex-col">
              <h2 className="text-purple font-bold text-start text-lg">
                Blink
              </h2>
              <p className="text-white text-start">
                Blink is a terminal app that helps you follow the 20-20-20 rule
                to help you with eye strain.
              </p>
            </div>
            <img
              src={pic1}
              className="w-full md:ml-8 md:w-1/4 lg:w-3/4 drop-shadow-xl"
              alt="screenshot of Blink's TUI"
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Projects;
