import React from "react";
import Skills from "./Skills";

const EXPERIENCES = [
  {
    company: "Resideo",
    dates: "Feb. 2022 - Present",
    title: "Software Engineer I",
    description:
      "During my time at Resideo, I have been a backend developer and played a pivotal role in building our GraphQL API. My contributions to the platform included developing new features, optimizing our CI/CD pipeline, providing support to our internal clients, handling our production deployments, troubleshooting production issues and delivering solutions quickly, and helping manage our Auth0 tenants.",
    skills: [
      "TypeScript",
      "JavaScript",
      "GraphQL",
      "Docker",
      "NestJS",
      "GitHub Actions",
      "GraphQL Apollo",
      "Node.js",
    ],
  },
  {
    company: "Recruitifi",
    dates: "Sept. 2021 - Feb. 2022",
    title: "Full Stack Software Engineer",
    description:
      "During my time at Recruitifi, I helped enhance our backend system. I embraced the challenge of mastering a new framework and programming language, diving deep into unfamiliar territory. This experience sharpened my ability to rapidly assimilate new technologies and apply them effectly.",
    skills: ["React", "JavaScript", "Ruby", "Ruby on Rails", "HTML", "CSS"],
  },
];

const Work = () => {
  return (
    <div className="w-full mt-5 md:-mt-0 md:ml-10 md:w-3/4 lg:w-3/4 sm:mx-auto">
      {EXPERIENCES.map((experience) => (
        <div className="md:ml-2 md:mb-8 lg:ml-2 lg:mb-8">
          <h2 className="text-purple font-bold text-start">
            {experience.company}
          </h2>
          <h2 className="text-white font-mono text-start">
            {experience.dates}
          </h2>
          <h2 className="text-pink text-start font-mono">{experience.title}</h2>
          <div className="flex flex-wrap gap-2">
            {experience.skills.map((tech) => (
              <Skills skill={tech} color={"bg-yellow"} />
            ))}
          </div>
          <p className="text-start text-white mt-2">{experience.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Work;
