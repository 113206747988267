import React from "react";
import Skills from "./Skills";
import { FaGithubAlt } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import photo from "./Caro-Commercial.png";

const SKILLS = [
  "React",
  "Azure",
  "JavaScript",
  "TypeScript",
  "Docker",
  "Git",
  "Python",
  "PostgreSQL",
  "Django",
  "Flask",
  "Node",
  "Sentry",
  "Auth0",
  "GitHub Actions",
  "GraphQL",
  "Apollo",
  "NestJS",
];

const LEARNING = ["Godot", "C#", "ASP.NET", "AWS", "Go", "Pixel Art"];

const AboutMe = () => {
  return (
    <div className="w-full mt-5 md:-mt-0 md:ml-10 md:w-3/4 lg:w-3/4 sm:mx-auto">
      <div className="flex flex-col md:flex-row">
        <img
          src={photo}
          className="w-full mt-5 md:w-1/4 md:-mt-5 md:mr-5 drop-shadow-xl flex justify-center"
          alt="drawing of caro and beanie"
        />
        <div className="flex flex-col md:flex-col">
          <h2 className="text-white text-center mt-5 md:text-start font-mono text-base">
            Hello, I'm Caroline 👋🏼
          </h2>
          <div className="flex flex-col items-center md:items-start mt-5">
            <a
              href="https://github.com/echocaro"
              className="flex items-center mr-2 text-lg"
            >
              <FaGithubAlt className="text-purple" />
              <span className="text-white ml-2 hover:text-pink">
                GitHub Profile
              </span>
            </a>
            <a
              href="https://www.linkedin.com/in/caro-mendez/"
              className="flex items-center text-lg"
            >
              <FaLinkedin className="text-purple" />
              <span className="text-white ml-2 hover:text-pink">
                LinkedIn Profile
              </span>
            </a>
          </div>
        </div>
      </div>
      <h2 className="text-white text-start font-mono pt-6 text-base">
        I'm a professional yapper, a wannabe gamer, and also a{" "}
        <span className="text-pink">Software Engineer</span>. When I'm not
        playing Stardew Valley or Apex Legends, I'm helping build and maintain a
        GraphQL API at Resideo. I have almost 3 years of experience where I've
        gotten to work with Ruby, JavaScript, React, NestJS, GraphQL, and much
        more. I like to spend some of my free time learning new technologies and
        challenging myself.
      </h2>

      <h2 className="text-white text-start font-mono pt-6 text-base">
        Outside of coding, I also love to read, go on walks with my pup, play
        video games, and play the guitar.
      </h2>

      <h2 className="text-white text-start font-mono mt-5 text-base">
        Skills:
      </h2>
      <div className="flex flex-wrap gap-2">
        {SKILLS.map((skill) => (
          <Skills skill={skill} color={"bg-pink"} />
        ))}
      </div>

      <h2 className="text-white text-start font-mono mt-5 text-base">
        Here are some things I'm currently learning:
      </h2>
      <div className="flex flex-wrap gap-2">
        {LEARNING.map((learning) => (
          <Skills skill={learning} color={"bg-blue"} />
        ))}
      </div>
    </div>
  );
};

export default AboutMe;
