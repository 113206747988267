import logo from "./logo.svg";
import "./App.css";
import Tabs from "./components/Tabs";
import AboutMe from "./components/AboutMe";
import { Route, Routes } from "react-router-dom";
import Work from "./components/Work";
import Blink from "./components/Projects/Blink";
import Projects from "./components/Projects/Projects";

function App() {
  return (
    <div className="text-center w-3/5 mt-[8%] ml-[20%] flex flex-col md:flex-row mb-5">
      <div>
        <Tabs />
      </div>
      <div>
        <Routes>
          <Route path="/" element={<AboutMe />} />
          <Route path="/work" element={<Work />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/blink" element={<Blink />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
